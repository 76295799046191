import React from 'react'

import Layout from 'components/layout'
import Container from 'components/container'
import { Main, Heading, Paragraph } from './styled'

const NotFoundPage = () => (
  <Layout>
    <Main>
      <Container>
        <Heading>Oops! Page Not Found.</Heading>
        <Paragraph>
          We can&apos;t seem to find the page you&apos;re looking for. Try using
          the links above!
        </Paragraph>
        <Paragraph>
          If you believe that this was in error or can&apos;t find what you are
          looking for, please contact us at{' '}
          <a href="mailto:support@meetfellow.com?subject=Help with 404 Error">
            support@meetfellow.com
          </a>
          .
        </Paragraph>
      </Container>
    </Main>
  </Layout>
)

export default NotFoundPage
