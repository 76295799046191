import styled from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'

export const Main = styled.main`
  padding: 14rem 0;
  text-align: center;
  background: ${theme.color.background.light};
`

export const Heading = styled.h1`
  ${heading.xxl};
`

export const Paragraph = styled.p`
  ${paragraph.normal}
`
